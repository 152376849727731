import React from 'react';
import {Link} from "gatsby";
import LinkedInImg from '../assets/icon_linkedin.svg';
import TwitterImg from '../assets/icon_twitter.svg';
import {
  contactEmail,
  linkedinLink,
  PAGES,
  twitterLink,
} from "../constants";

const Footer = () => (
  <footer id="footer">
    <a href={twitterLink} className="footer-icon" target="_blank" rel="noreferrer noopener">
      <img src={TwitterImg} alt="Twitter" />
    </a>
    <a href={linkedinLink} className="footer-icon" target="_blank" rel="noreferrer noopener">
      <img src={LinkedInImg} alt="Linked In" />
    </a>
    <br />
    <a href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer noopener">{contactEmail}</a>&emsp;&emsp;
    <Link to={PAGES.CONTACT_US}>Contact&nbsp;us</Link>&emsp;&emsp;
    <Link to={PAGES.PRIVACY_POLICY}>Privacy&nbsp;policy</Link><br />
    &copy; Euclidxr Immersive pvt. ltd. Bangalore, India
  </footer>
);

export default Footer;
