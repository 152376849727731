import React from 'react';
import '../index.scss';

import PageMetaDefault from "./page-meta-default";
import Header from "./header";
import Footer from "./footer";

class PolicyHoc extends React.PureComponent {
  render() {
    const { children, pageTitle } = this.props;
    return (
      <>
        <PageMetaDefault pageTitle={pageTitle} />
        <Header />
        <div className="width-limit">
          <div id="policy-contents">
            { children }
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default PolicyHoc;
